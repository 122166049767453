<template>
  <v-container>
    <div style="margin: 10px">
      <v-btn @click="$router.back()" outlined small link
        ><v-icon>mdi-arrow-left</v-icon> Back</v-btn
      >
      Objectives Targets List
    </div>
    <div class="top-cards">
      <div class="institution-categories success-goal">
        <div class="percentage-perfom">4</div>
        <div class="category-name">Total Targets</div>
      </div>
      <div class="institution-categories success-goal">
        <div class="percentage-perfom">1</div>
        <div class="category-name">Completed</div>
      </div>
      <div class="institution-categories success-goal">
        <div class="percentage-perfom">2</div>
        <div class="category-name">Overdue</div>
      </div>
    </div>
    <v-card class="col-md-12">
      <v-data-table :headers="headers" :items="desserts">
        <template v-slot:item.actions="{ item }">
              <v-menu :key="item.name" offset-y app>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                    icon
                    color="primary"
                    ><v-icon>mdi-dots-vertical</v-icon></v-btn
                  >
                </template>
                <v-list dense>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-summit</v-icon
                      >
                      Set Baseline</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="implementations">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-numeric-positive-1</v-icon
                      >
                      Report Implementations</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-timeline-plus-outline</v-icon
                      >
                      Set Plan</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-compass</v-icon
                      >
                      View Progress</v-list-item-title
                    >
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-delete</v-icon
                      >
                      Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            :color="
              item.progress > 60
                ? 'green'
                : item.progress < 45
                ? 'orange'
                : 'blue'
            "
            v-model="item.progress"
            :buffer-value="100"
            height="20"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Target Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Implementation Time", value: "timespan" },
        { text: "Due Date", value: "duedate" },
        { text: "Current Progress", value: "progress" },
        { text: "Actions", value: "actions" },
      ],

      desserts: [
        {
          name: "Increase Amount of Healthcare facility density",
          timespan: "2 Years",
          duedate: "July, 2024",
          progress: 76,
          actions: "",
        },
        {
          name: "Increase percentage of people with access to clean water",
          timespan: "1 Year",
          duedate: "July, 2023",
          progress: 23,
          actions: "",
        },
        {
          name: "Uplifting Median household income to 5500 Tsh per day",
          timespan: "6 Years",
          duedate: "March, 2027",
          progress: 45,
          actions: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.top-cards {
  display: flex;
  flex-direction: row;
  margin: 10px;
}
.institution-categories {
  border: 0.5px solid #5c7faf9c;
  height: 84px;
  margin: 2px;
  cursor: pointer;
  max-width: 19.1%;
  min-width: 19.5%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
}
.percentage-perfom {
  font-size: 30px;
  font-weight: bold;
}
</style>
