<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <h3>Development Vision 2025 Implementation Dashboard</h3>
      <div class="dashboard-container">

          <div class = "measured-issues">Poverty</div>
          
        </div>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  name: "DashBoard",
  data: () => ({
    hovered: true,
    overlay: false,
    componentDialog: false,
    detail: "/components/details/",
    sample: 1,
    component_list: [
      { name: "Digital Ecosystem", id: 1 },
      { name: "Digital Connectivity", id: 2 },
      { name: "Digital Platforms and Services", id: 3 },
    ],
  }),
};
</script>


<style scoped>
.dashboard-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.measured-issues{
  margin: 2px;
  box-sizing: 20px;
  border: 0.5px solid #2950999c;
  padding: 2px;
  text-align: center;
  height: 70px;
  align-items: center;
  width: 24%;
  border-radius: 5px;
  color: #0b1320;
}
</style>



