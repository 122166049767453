<template>
  <v-container>
    <h4 style="color:#111c2b9c">
        IMPLEMENTATION MATRICES OF FYDP III INTERVENTIONS
    </h4>
    <div>
        <v-card elevation="0" link to="list-intervantions" v-for="area in items" :key="area.text" class="focus-area">
            <h4>{{ area.text }}</h4>
            <small style="color:#111c2b9c">{{ area.icon }}</small>
        </v-card>

    </div>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      items: [
        { text: 'Competitiveness Enhancement', icon: 'Focusing on measures to make the country more competitive.' },
        { text: 'Industrialization', icon: 'Aiming to deepen industrial development.' },
        { text: 'Trade and Investment', icon: 'Promoting participation in global and regional trade.' },
        { text: 'Social Development', icon: 'Ensuring equitable access to quality services for inclusive growth.' },
        { text: 'Skills Development', icon: 'Enhancing the workforce skills and capabilities.' },
      ],
    }),
  }
</script>

<style scoped>
.focus-area{
    border: 1px solid #2950994d;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: start;
    border-radius: 3px;
    margin: 15px;
    text-align: center;
    box-shadow: 2px 2px 5px -5px #29509975;
    padding: 10px;
    cursor: pointer;
}
</style>