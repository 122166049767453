<template>
  <v-container>
    <div style="margin: 10px">
      <v-btn @click="$router.back()" outlined small link
        ><v-icon>mdi-arrow-left</v-icon> Back</v-btn
      >
      Implementations reports toward reaching target
    </div>
    <div class="report-container">
      <div class="report-side-panel">
        <v-card class="mx-auto" width="256" tile>
          <v-navigation-drawer >
            <v-system-bar height="50"><h3>Filter Reports</h3></v-system-bar>
            <v-list nav dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </div>
      <div class="report-main-panel">
        <div class="main-filter">
          <div style="width: 300px">
            <v-text-field
              label="Search Reports"
              placeholder="Report name"
              outlined
              dense
              background-color="white"
              prepend-inner-icon="mdi-folder-search-outline"
            ></v-text-field>
          </div>
          <v-btn small color="primary"
            ><v-icon>mdi-plus</v-icon> Report Implementation</v-btn
          >
        </div>
        <v-divider></v-divider>
        <div class="reports-panel">
            <div class="report-card" v-for="report in diferentReports" :key="report">
                <v-icon size="130" color="#c79936">mdi-folder-open</v-icon>
                <strong>{{ report }}</strong>
            </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      selectedItem: 0,
      items: [
        { text: 'All Reports', icon: 'mdi-folder' },
        { text: 'By Input Indicators (4)', icon: 'mdi-import' },
        { text: 'By Output Indicators (3)', icon: 'mdi-export' },
        { text: 'By Process Indicators (6)', icon: 'mdi-sync' },
        { text: 'By Results Indicators (11)', icon: 'mdi-golf' },
        { text: 'Newest Only', icon: 'mdi-history' },
        { text: 'Archives', icon: 'mdi-cloud-upload' },
      ],
      diferentReports:[
        'Microfinance Initiatives',
        'Skill Development Training',
        'Farmers Cooperatives',
        'School Construction',
        'Public Awareness Campaigns',
        'Scholarship Programs',
        'Financial Inclusion',
        "Women's Empowerment",
        'Social Assistance Programs',
      ]
    }),
  }
</script>

<style scoped>
.report-container {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.report-side-panel {
  width: 30%;
  padding: 10px;
}
.report-main-panel {
  width: 70%;
  padding: 10px;
}
.main-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.reports-panel{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.report-card{
    width: 190px;
    border: 1px solid #2950994d;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    border-radius: 3px;
    margin: 15px;
    text-align: center;
    box-shadow: 2px 2px 8px -4px #29509975;
    padding: 10px;
    cursor: pointer;
}
</style>
