<template>
  <div>
    <div class="page-top-container">
      <div class="meetings-card">
        <div class="general-perfomance">
          <div class="text-name">Livelihood KPI's</div>
          <div class="g-percentage"><h1>49.8%</h1></div>
        </div>
        <div class="general-body">
          <h1 class="body-text">1.4%</h1>
          <v-icon color="success">mdi-arrow-down</v-icon>
          <div class="text-name">Health Disparities</div>
        </div>
        <div class="general-body">
          <h1 class="body-text">1.8%</h1>
          <v-icon color="success">mdi-arrow-up</v-icon>
          <div class="text-name">Infrastructure Disparities</div>
        </div>
        <div class="general-body">
          <h1 class="body-text">1.2%</h1>
          <v-icon color="pink">mdi-arrow-up</v-icon>
          <div class="text-name">Digital Disparities</div>
        </div>
        <div class="general-body-last">
          <h1 class="body-text">0.5%</h1>
          <v-icon color="success">mdi-arrow-down</v-icon>
          <div class="text-name">Opportunities Disparities</div>
        </div>
      </div>
    </div>
    <div class="page-second-container">
      <div>
        <table>
          <thead>
            <tr>
              <th rowspan="2"><strong>S/No.</strong></th>
              <th rowspan="2"><strong>Key Livelihood Indicators</strong></th>
              <th colspan="2" rowspan="2"><strong>Baseline</strong></th>
              <th colspan="2"><strong>Intermediate Targets</strong></th>
              <th colspan="2" rowspan="2"><strong>End Targets</strong></th>
              <th colspan="2" rowspan="2"><strong>Progress</strong></th>
              <th colspan="2" rowspan="2"><strong>Actions</strong></th>
            </tr>
            <tr>
              <th><strong>1</strong></th>
              <th><strong>2</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in reportData" :key="item.name">
              <td>1.0</td>
              <td>{{ item.name }}</td>
              <td colspan="2">{{ item.duration }}</td>
              <td>{{ item.duration }}</td>
              <td>{{ item.duration }}</td>
              <td colspan="2">{{ item.duration }}</td>
              <td colspan="2">
                <span>
                  <v-progress-linear
                    :color="
                      item.progress > 60
                        ? 'green'
                        : item.progress < 45
                        ? 'orange'
                        : 'blue'
                    "
                    v-model="item.progress"
                    :buffer-value="100"
                    height="20"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </span>
              </td>
              <td colspan="2">
                <v-btn link to="targets" small  outlined><v-icon small>mdi-eye</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h4>Regional Overall Livelihood Index</h4>
    <div class="page-regional-container">
      <div v-for="region in regional" :key="region.name" :class="{ 'regional-card': true, 'success': region.percentage >= 60, 'medium': region.percentage >= 40 && region.percentage <= 59, 'error': region.percentage <40, }">
        <div>{{ region.name }}</div>
        <div class="regional-card-lower">
            <div><small>Position: <strong>{{ region.position }}</strong></small></div>
            <div>
              {{ region.percentage }} %
              <v-icon :class="{'success': region.percentage >= 60, 'medium': region.percentage >= 40 && region.percentage <= 59, 'error': region.percentage <40, }" size="13">{{ region.icon }}</v-icon>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title: "urban-rural disparities",
      reportData: [
        {
          name: `Income Levels`,
          duration: 4781.01,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 56,
        },
        {
          name: ` Life expectancy`,
          duration: 240.0,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
        },
        {
          name: `Literacy rates`,
          duration: 25.0,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 49,
        },
        {
          name: `Employment rates`,
          duration: 46.7,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 22,
        },
        {
          name: `Housing quality`,
          duration: 0.0,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
        },
      ],
      regional: [
        {
          name: "Arusha",
          position: 1,
          percentage: 65,
          icon: "mdi-arrow-up",
        },
        {
          name: "Dar es Salaam",
          position: 2,
          percentage: 63.5,
          icon: "mdi-arrow-up",
        },
        {
          name: "Dodoma",
          position: 3,
          percentage: 61,
          icon: "mdi-arrow-up",
        },
        {
          name: "Geita",
          position: 4,
          percentage: 60,
          icon: "mdi-arrow-up",
        },
        {
          name: "Iringa",
          position: 5,
          percentage: 59,
          icon: "mdi-arrow-up",
        },
        {
          name: "Kagera",
          position: 6,
          percentage: 58,
          icon: "mdi-arrow-up",
        },
        {
          name: "Katavi",
          position: 7,
          percentage: 57,
          icon: "mdi-arrow-up",
        },
        {
          name: "Kigoma",
          position: 8,
          percentage: 56,
          icon: "mdi-arrow-up",
        },
        {
          name: "Kilimanjaro",
          position: 9,
          percentage: 55,
          icon: "mdi-arrow-up",
        },
        {
          name: "Lindi",
          position: 10,
          percentage: 54,
          icon: "mdi-arrow-up",
        },
        {
          name: "Manyara",
          position: 11,
          percentage: 53,
          icon: "mdi-arrow-up",
        },
        {
          name: "Mara",
          position: 12,
          percentage: 52,
          icon: "mdi-arrow-up",
        },
        {
          name: "Mbeya",
          position: 13,
          percentage: 51,
          icon: "mdi-arrow-up",
        },
        {
          name: "Morogoro",
          position: 14,
          percentage: 50,
          icon: "mdi-arrow-up",
        },
        {
          name: "Mtwara",
          position: 15,
          percentage: 49,
          icon: "mdi-arrow-up",
        },
        {
          name: "Mwanza",
          position: 16,
          percentage: 48,
          icon: "mdi-arrow-up",
        },
        {
          name: "Njombe",
          position: 17,
          percentage: 47,
          icon: "mdi-arrow-up",
        },
        {
          name: "Pemba North",
          position: 18,
          percentage: 46,
          icon: "mdi-arrow-up",
        },
        {
          name: "Pemba South",
          position: 19,
          percentage: 45,
          icon: "mdi-arrow-up",
        },
        {
          name: "Pwani (Coast)",
          position: 20,
          percentage: 44,
          icon: "mdi-arrow-up",
        },
        {
          name: "Rukwa",
          position: 21,
          percentage: 43,
          icon: "mdi-arrow-up",
        },
        {
          name: "Ruvuma",
          position: 22,
          percentage: 42,
          icon: "mdi-arrow-up",
        },
        {
          name: "Shinyanga",
          position: 23,
          percentage: 41,
          icon: "mdi-arrow-up",
        },
        {
          name: "Simiyu",
          position: 24,
          percentage: 40,
          icon: "mdi-arrow-up",
        },
        {
          name: "Singida",
          position: 25,
          percentage: 39,
          icon: "mdi-arrow-up",
        },
        {
          name: "Songwe",
          position: 26,
          percentage: 38,
          icon: "mdi-arrow-up",
        },
        {
          name: "Tabora",
          position: 27,
          percentage: 37,
          icon: "mdi-arrow-up",
        },
        {
          name: "Tanga",
          position: 28,
          percentage: 36,
          icon: "mdi-arrow-up",
        },
        {
          name: "Unguja North",
          position: 29,
          percentage: 35,
          icon: "mdi-arrow-up",
        },
        {
          name: "Unguja Central",
          position: 30,
          percentage: 34,
          icon: "mdi-arrow-up",
        },
        {
          name: "Unguja South",
          position: 30,
          percentage: 34,
          icon: "mdi-arrow-up",
        },
        {
          name: "Zanzibar Central",
          position: 31,
          percentage: 33,
          icon: "mdi-arrow-up",
        },
        {
          name: "Zanzibar South",
          position: 31,
          percentage: 33,
          icon: "mdi-arrow-up",
        },
      ],
    };
  },
};
</script>

<style scoped>
.page-top-container {
  display: flex;
  flex-direction: row;
}
.page-second-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.meetings-card {
  background-color: white;
  border: 0.2px solid #09162e93;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
}

.general-perfomance {
  margin-right: 2px;
  padding-right: 5px;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  text-align: center;
}
.g-percentage {
  color: rgb(30, 138, 75);
}
.text-name {
  color: #09162eb4;
  font-weight: 500;
}

.general-body {
  margin-right: 1px;
  padding-right: 2px;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-body-last {
  margin-right: 2px;
  padding-right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.body-text {
  font-size: 50px;
  color: #09162eb4;
  margin-left: 8px;
  margin-right: 5px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #295099a2;
  border: 1px solid #ffffff;
  color: var(--dtp-font-white);
}

th {
  text-align: center;
  padding: 5px;
  border-top: none;
  border: 0.2px solid var(--dtp-font-dark);
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid var(--dtp-font-dark);
}

.page-regional-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}
.regional-card {
  width: 200px;
  height: 60px;
  border: 1px solid #295099a2;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: 2px;
}
.regional-card-lower{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.medium {
    background: rgba(233, 150, 54, 0.712);
}
</style>
