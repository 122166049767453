var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticStyle:{"margin":"10px"}},[_c('v-btn',{attrs:{"outlined":"","small":"","link":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Back")],1),_vm._v(" Objectives Targets List ")],1),_c('div',{staticClass:"top-cards"},[_c('div',{staticClass:"institution-categories success-goal"},[_c('div',{staticClass:"percentage-perfom"},[_vm._v("4")]),_c('div',{staticClass:"category-name"},[_vm._v("Total Targets")])]),_c('div',{staticClass:"institution-categories success-goal"},[_c('div',{staticClass:"percentage-perfom"},[_vm._v("1")]),_c('div',{staticClass:"category-name"},[_vm._v("Completed")])]),_c('div',{staticClass:"institution-categories success-goal"},[_c('div',{staticClass:"percentage-perfom"},[_vm._v("2")]),_c('div',{staticClass:"category-name"},[_vm._v("Overdue")])])]),_c('v-card',{staticClass:"col-md-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{key:item.name,attrs:{"offset-y":"","app":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":"","color":"primary"},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-summit")]),_vm._v(" Set Baseline")],1)],1),_c('v-list-item',{attrs:{"link":"","to":"implementations"}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-numeric-positive-1")]),_vm._v(" Report Implementations")],1)],1),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-timeline-plus-outline")]),_vm._v(" Set Plan")],1)],1),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-compass")]),_vm._v(" View Progress")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete")],1)],1)],1)],1)]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":item.progress > 60
              ? 'green'
              : item.progress < 45
              ? 'orange'
              : 'blue',"buffer-value":100,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.progress),callback:function ($$v) {_vm.$set(item, "progress", $$v)},expression:"item.progress"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }