<template>
    <v-container>
    <div style="margin: 10px">
      <v-btn @click="$router.back()" outlined small link
        ><v-icon>mdi-arrow-left</v-icon> Back</v-btn
      >
      Targets Indicators List
    </div>
      
      <v-card class="col-md-12">
        <v-data-table :headers="headers" :items="desserts">
          <template v-slot:item.progress="{ item }">
            <v-progress-linear
              :color="
                item.progress > 60
                  ? 'green'
                  : item.progress < 45
                  ? 'orange'
                  : 'blue'
              "
              v-model="item.progress"
              :buffer-value="100"
              height="20"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        headers: [
          {
            text: "Indicators Name",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Implementation Time", value: "timespan" },
          { text: "Due Date", value: "duedate" },
          { text: "Current Progress", value: "progress" },
        ],
  
        desserts: [
          {
            name: "Efficient, reliable and secure postal and electronic communications infrastructure and applications promoted",
            timespan: "2 Years",
            duedate: "July, 2024",
            progress: 76,
            actions: "",
          },
          {
            name: "Accessibility, affordability and quality of electronic and postal communications services enhanced",
            timespan: "1 Year",
            duedate: "July, 2023",
            progress: 23,
            actions: "",
          },
          {
            name: "Regulated services, innovation, local contents generation and localization enhanced",
            timespan: "6 Months",
            duedate: "March, 2023",
            progress: 45,
            actions: "",
          },
          {
            name: "Performance of regulated services and stakeholders’ protection enhanced",
            timespan: "18 Months",
            duedate: "December, 2024",
            progress: 34,
            actions: "",
          },
        ],
      };
    },
  };
  </script>
  
  <style></style>
  