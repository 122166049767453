<template>
  <v-container>
    <h4 style="color: #111c2b9c">AGRICULTURAL IMPROVEMENT</h4>
    <div>
      <div class="top-cards">
        <div @click="$router.push('indicators-list')" class="focus-area warning-card">
          <v-icon size="50">mdi-cash-100</v-icon>
          <div class="percentage-perfom">19,890,639,800,000 TSh</div>
          <div class="category-name">Sector Allocated Budget</div>
        </div>
        <div @click="$router.push('indicators-list')" class="focus-area warning-card">
          <v-icon size="50">mdi-cash-100</v-icon>
          <div class="percentage-perfom">2,000,000,000 TSh</div>
          <div class="category-name">Accumulative Disbusment</div>
        </div>
        <div @click="$router.push('indicators-list')" class="focus-area warning-card">
          <v-icon size="50">mdi-cash-100</v-icon>
          <div class="percentage-perfom">69,772,400,000,000 TSh</div>
          <div class="category-name">Private Sectors Contributions</div>
        </div>
        <div @click="$router.push('indicators-list')" class="focus-area warning-card">
          <v-icon size="50">mdi-cash-100</v-icon>
          <div class="percentage-perfom">89,665,039,800,000 TSh</div>
          <div class="category-name">DP's Contributions</div>
        </div>
      </div>

      <h4 class="dashboard-heads" style="color: #111c2b9c">
        IRRIGATION SECTOR PROGRESS SUMMARY
      </h4>

      <div class="second-cards">
        <div style="width:60%; margin-right: 5px;">
            <hr />
                <div class="irrigation-level">
                <div class="i">
                    <div class="category-name">
                    <strong> Total Potential Area for Irrigation </strong>
                    </div>
                    <div class="percentage-perfom">560003433 Acres</div>
                </div>
                <div class="i">
                    <div class="category-name">
                    <strong> Cultivated Area </strong>
                    </div>
                    <div class="percentage-perfom">
                    210003433 Acres <br /><small
                        ><span style="color: rgba(255, 60, 0, 0.795)">40%</span> of
                        Total Area</small
                    >
                    </div>
                </div>
                </div>
            <hr />
                <div class="irrigation-level">
                <div class="i">
                    <div class="category-name">
                    <strong> Woman Involved </strong>
                    </div>
                    <div class="percentage-perfom">
                    210003433 <br /><small
                        ><span style="color: rgba(10, 131, 64, 0.753)">56%</span> of all
                        People</small
                    >
                    </div>
                </div>
                <div class="i">
                    <div class="category-name">
                    <strong> Woman Involved </strong>
                    </div>
                    <div class="percentage-perfom">
                    210003433 <br /><small
                        ><span style="color: rgba(255, 60, 0, 0.795)">44%</span> of all
                        People</small
                    >
                    </div>
                </div>
                </div>
            <hr />
                <div class="irrigation-level">
                <div class="i">
                    <div class="category-name">
                    <strong> Food Crop Produced </strong>
                    </div>
                    <div class="percentage-perfom">
                    210003433 Tons <br /><small
                        ><span style="color: rgba(10, 131, 64, 0.753)">6%</span> of all
                        food crop produced</small
                    >
                    </div>
                </div>
                <div class="i">
                    <div class="category-name">
                    <strong> Cash Crop Produced </strong>
                    </div>
                    <div class="percentage-perfom">
                    210003433 Tons <br /><small
                        ><span style="color: rgba(255, 60, 0, 0.795)">4%</span> of all
                        food crop produced</small
                    >
                    </div>
                </div>
                </div>
            <hr />
        </div>
        <div class="project-list" style="width:35%;">
            <h4 class="dashboard-heads" style="color: #111c2b9c;">
                STRATEGIC AGRICULTURAL PROJECTS
            </h4>
            <v-card elevation="0" link to="list-intervantions" v-for="area in items" :key="area.text" class="project-card">
                <h4>{{ area.text }}</h4>
                <small style="color:#111c2b9c">{{ area.icon }}</small>
            </v-card>
            <v-btn style="margin: 5px;" small class="primary" text> More Projects</v-btn>
            <v-btn style="margin: 5px;" small class="primary" text> Challenges</v-btn>
        </div>
      </div>



      <h4 class="dashboard-heads" style="color: #111c2b9c; margin-top:20px">
        TOP 20 MOST EXPORTED CROPS
      </h4>

      <v-card elevation="0">
        <table>
          <thead>
            <tr>
              <th rowspan="2">S/No.</th>
              <th rowspan="2">Crop</th>
              <th colspan="2">Production in Tons</th>
              <th colspan="2">Export in Tons</th>
              <th colspan="2" rowspan="2">Amount</th>
            </tr>
            <tr>
              <th>Last Year</th>
              <th>This Year</th>

              <th>Last Year</th>
              <th>This Year</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, Index) in reportData" :key="item.name">
              <td>{{ Index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.thisyear01 }}</td>
              <td>{{ item.lastyear01 }}</td>
              <td>{{ item.thisyear02 }}</td>
              <td>{{ item.lastyear02 }}</td>
              <td colspan="2">{{ item.expected }}</td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    reportData: [
      {
        name: `Maize`,
        thisyear01: `200,309`,
        lastyear01: "200,309",
        thisyear02: "5,817,690",
        lastyear02: "5,817,690",
        expected: "5,817,690",
      },
      {
        name: `Beans`,
        thisyear01: `200,309`,
        lastyear01: "200,309",
        thisyear02: "5,817,690",
        lastyear02: "5,817,690",
        expected: "5,817,690",
      },
      {
        name: `Cashewnuts`,
        thisyear01: `200,309`,
        lastyear01: "200,309",
        thisyear02: "5,817,690",
        lastyear02: "5,817,690",
        expected: "5,817,690",
      },
      {
        name: `Overcado`,
        thisyear01: `200,309`,
        lastyear01: "200,309",
        thisyear02: "5,817,690",
        lastyear02: "5,817,690",
        expected: "5,817,690",
      },
    ],
    items:[
        {text: "BBT",icon:"Building a Better Tomorrow (BBT)"},
        {text: "AGRI-CONNECT", icon:"EU-funded programme, contributing towards inclusive economic growth"},
        {text: "ASDP II",icon:"Agricultural Sector Development Programme Phase II (ASDP II)"},
    ]
  }),
};
</script>

<style scoped>
.second-cards{
    display: flex;
    flex-direction: row;
}
.project-list{
    margin-left: 20px;
}
.project-card{
    border: 1px solid #2950994d;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: start;
    border-radius: 3px;
    margin: 15px;
    text-align: center;
    box-shadow: 2px 2px 5px -5px #29509975;
    padding: 10px;
    cursor: pointer;
}
.top-cards {
  display: flex;
  flex-direction: row;
}
.percentage-perfom {
  font-size:20px;
  font-weight: bold;
  color: #28467ade;
}
.focus-area {
  width: 25%;
  height: 150px;
  border: 1px solid #2950994d;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 3px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  box-shadow: 2px 2px 5px -5px #29509975;
  padding: 10px;
  cursor: pointer;
}

.institution-categories {
  border: 0.5px solid #5c7faf9c;
  height: 95px;
  margin: 2px;
  cursor: pointer;
  max-width: 19.1%;
  min-width: 19.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #224e9ede;
  color: var(--dtp-font-white);
}

th {
  text-align: center;
  padding: 3px;
  border: 1px solid white;
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid #2950994d;
}

.irrigation-level {
  display: flex;
  margin: 10px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
</style>
