<template>
    <sequential-entrance fromBottom>
      <v-app-bar elevation="2" app dense color="white" dark>
        <v-btn link to="/" outlined><v-icon :color="'#295099'">mdi-home</v-icon></v-btn>
  
        <div class="d-flex align-center">
          <v-toolbar-title dark class="text">
            <span style="color: #295099">International Relations & Embassies</span>
          </v-toolbar-title>
        </div>
  
        <v-spacer></v-spacer>
  
        <span style="color: #295099" class="mr-2">user@me.go.tz</span>
        <!-- </v-btn> -->
  
        <v-menu rightDrawer offset-y transition="fab-transition">
          <template style="color: #295099" v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon style="color: #295099">mdi-menu-down</v-icon>
            </v-btn>
          </template>
  
          <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" link>
                <v-list-item-icon>
                  <v-icon :color="item.color" v-text="item.icon"> </v-icon
                ></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>
  
  
      <v-main>
          <v-container>
            <center>
                <div class="categories-container">
                    <div @click="$router.push('dashboard')" class="institution-categories" v-for="category in categories" :key="category">
                        <v-icon size="50">mdi-bank-outline</v-icon>
                        <div class="category-name">{{ category }}</div>
                    </div>
                </div>
            </center>
          </v-container>
      </v-main>
    </sequential-entrance>
  </template>
  
  <script>
  export default {
      data() {
          return({
              categories: [
                'Abu Dhabi, United Arab Emirates',
                'Abuja, Nigeria',
                'Addis Ababa, Ethiopia',
                'Algiers, Algeria',
                'Ankara, Turkey',
                'Beijing, China',
                'Berlin, Germany',
                'Brasilia, Brazil',
                'Brussels, Belgium',
                'Bujumbura, Burundi',
                'Cairo, Egypt',
                'Doha, Qatar',
                'Geneva, Switzerland',
                'Harare, Zimbabwe',
                'Havana, Cuba',
                'Kampala, Uganda',
                'Khartoum, Sudan',
                'Kigali, Rwanda',
                'Kinshasa, Congo',
                'Kuala Lumpur, Malaysia',
                'Kuwait City, Kuwait',
                'Lilongwe, Malawi',
                'London, United Kingdom',
                'Lusaka, Zambia',
                'Maputo, Mozambique',
                'Mombasa, Kenya',
                'Moroni, Comoros',
                'Moscow, Russian Federation',
                'Muscat, Oman',
                'Nairobi, Kenya',
                'New Delhi, India',
                'New York, United States',
                'Ottawa, Canada',
                'Paris, France',
                'Perth, Australia',
                'Pretoria, South Africa',
                'Riyadh, Saudi Arabia',
                'Rome, Italy',
                'Seoul, Korea (South)',
                'Stockholm, Sweden',
                'Tel Aviv, Israel',
                'Tokyo, Japan',
                'Washington, D.C., United States',
              ]
          })
      }
  };
  </script>
  
  <style scoped>
  .categories-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 20px;
  }
  .institution-categories{
      border: 0.5px solid #2950999c;
      height: 120px;
      margin: 4px;
      cursor: pointer;
      max-width: 15%;
      min-width: 15%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 3px;
      padding: 5px;
      color: #09162e9c;
      box-shadow: -1px 2px 5px -2px rgba(0,0,0,0.88);
  }
  
  .category-name{
      text-align: center;
  }
  
  </style>
  