<template>
  <sequential-entrance fromBottom>
    <v-app-bar elevation="2" app dense color="white" dark>
      <v-btn link to="/home" outlined><v-icon :color="'#295099'">mdi-home</v-icon></v-btn>

      <div class="d-flex align-center">
        <v-toolbar-title dark class="text">
          <span style="color: #295099">Institution's Strategic Plan (SP) Monitoring & Evaluation (M&E)</span>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <span style="color: #295099" class="mr-2">user@me.go.tz</span>
      <!-- </v-btn> -->

      <v-menu rightDrawer offset-y transition="fab-transition">
        <template style="color:#295099" v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon style="color: #295099">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i" link>
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"> </v-icon
              ></v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>


    <v-main fluid>
        <v-container>
            <div class="categories-container">
                <div @click="$router.push('institution-list')" class="institution-categories" v-for="category in categories" :key="category">
                    <v-icon size="50">mdi-bank-outline</v-icon>
                    <div class="category-name">{{ category }}</div>
                </div>
            </div>

            <div class="categories-container">
                <div @click="$router.push('/jointsectors/dashboard')" class="institution-categories">
                    <v-icon size="50">mdi-message-draw</v-icon>
                    <div class="category-name">Joint Sector Reviews</div>
                </div>
            </div>
        </v-container>
    </v-main>
  </sequential-entrance>
</template>

<script>
export default {
    data() {
        return({
            categories: [
                'Ministries, Departments, & Agencies',
                'Embassies & International Organizations',
                'Reginal Hospitals & Distric Hospitals',
                'Regional Secretariates',
                'Local Government Authorities'
            ]
        })
    }
};
</script>

<style scoped>
.categories-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}
.institution-categories{
    border: 0.5px solid #2950999c;
    height: 115px;
    margin: 4px;
    cursor: pointer;
    max-width: 19.1%;
    min-width: 19.1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    padding: 5px;
    color: #09162e9c;
    box-shadow: -1px 2px 5px -2px rgba(0,0,0,0.88);
}

.category-name{
    text-align: center;
}

</style>
