<template>
    <sequential-entrance fromBottom>
      <v-container fluid>
        <line-chart :data="chartData"></line-chart>
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="1">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="componentDialog = !componentDialog"
                  v-bind="attrs"
                  v-on="on"
                  right
                  fab
                  small
                  color="success white--text"
                  style="margin: 5px"
                >
                  <v-icon>mdi-clipboard-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>Add New Component</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
            <div
              style="padding: 10px"
              v-for="component in component_list"
              :key="component"
            >
              <v-card
                class="w-auto"
                link
                to="component_details"
                max-width="85%"
                outlined
              >
                <div class="card-body">
                    <div class="card-name">{{component.name}}</div>
                </div>
  
                <v-card-actions>
                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        right
                        top
                        absolute
                        rounded
                        color="success"
                        x-small
                        link
                        to="component_details"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Component</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </div>
        </v-row>
  
        <v-dialog v-model="componentDialog" max-width="60%">
          <v-card>
            <v-card-title><v-icon>mdi-plus</v-icon> New Component</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                v-model="name"
                :error-messages="nameErrors"
                :counter="10"
                label="Name"
                prepend-inner-icon="mdi-text"
                required
              ></v-text-field>
  
              <v-text-field
                v-model="first"
                label="Descriptions"
                prepend-inner-icon="mdi-text"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="pink darken-1"
                class="white--text"
                small
                @click="componentDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success darken-1"
                small
                class="white--text"
                @click="componentDialog = false"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </sequential-entrance>
  </template>
  
  <script>
  export default {
    name: "DashBoard",
    data: () => ({
      hovered: true,
      overlay: false,
      componentDialog: false,
      detail: "/components/details/",
      sample: 1,
      component_list: [
      {
        name: "Sectorial Dashboard",
        icon: "mdi-domain",
        routinglink: "/sectorial/dashboard",
      },
      {
        name: "Health",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Education",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Finance & Planning",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Information, Communication, & ICT",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Constitution & Laws",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Agriculture",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Mining",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Community Development, Gender, Women, & Special Groups",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Judiciary",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Water",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Natural Resources & Tourism",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Environment",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Livestock & Fisheries",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Union Affairs",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Energy",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "TAMISEMI",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Transportation Sector",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Policy, Coordination, & Parliament",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Immigration",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "International Relations",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Infrastructure & Transportation",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Defense & National Service (JKT)",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Citizen Security",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Culture, Arts, & Sports",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Public Service & Good Governance",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Investment",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Youth & Employment",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Industry & Commerce",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      }
    ],
      chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]]
    }),
  };
  </script>
  

<style scoped>
.card-body{
  padding: 2px;
}
.card-name{
  text-align: center;
}
</style>
  
  
  