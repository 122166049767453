<template>
  <sequential-entrance fromBottom>
    <v-app-bar elevation="2" app dense color="white" dark>
      <v-btn link to="/" outlined><v-icon :color="'#295099'">mdi-home</v-icon></v-btn>

      <div class="d-flex align-center">
        <v-toolbar-title dark class="text">
          <span style="color: #295099">Institution's Strategic Plan (SP) Monitoring & Evaluation (M&E)</span>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <span style="color: #295099" class="mr-2">user@me.go.tz</span>
      <!-- </v-btn> -->

      <v-menu rightDrawer offset-y transition="fab-transition">
        <template style="color: #295099" v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon style="color: #295099">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i" link>
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"> </v-icon
              ></v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>


    <v-main fluid>
      <v-container>
          <v-btn color="primary" @click="$router.back()" outlined small> <v-icon>mdi-arrow-left</v-icon> Back</v-btn>
          <div class="categories-container">
                <div @click="$router.push('dashboard')" class="institution-list" v-for="category in categories" :key="category">
                    <v-avatar>
                      <img :src="category.img" alt="">
                    </v-avatar>
                    <div class="institution-name">{{ category.name }}</div>
                </div>
            </div>
        </v-container>
    </v-main>
  </sequential-entrance>
</template>

<script>
export default {
    data() {
        return({
            categories: [
              {
                name: "Tanzania Communications Regulatory Authority (TCRA)",
                img: require("@/assets/tcra.jpeg")
              },
              {
                name: "Tanzania Ports Authority (TPA)",
                img: require("@/assets/tpa.png")
              },
              {
                name: "e-Government Authority (eGA)",
                img: require("@/assets/ega.png")
              },
              {
                name: "Tanzania Electric Supply Company (TANESCO)",
                img: require("@/assets/tanesco.jpeg")
              },
              {
                name: "Tanzania Railways Corporation (TRC)",
                img: require("@/assets/trc.jpg")
              },
              {
                name: "Tanzania Revenue Authority (TRA)",
                img: require("@/assets/tra.png")
              },
              {
                name: "Higher Education Students' Loans Board (HESLB)",
                img: require("@/assets/helsb.jpeg")
              },
              {
                name: "National Identification Authority (NIDA)",
                img: require("@/assets/nida.jpeg")
              },
              {
                name: "Medical Stores Department (MSD)",
                img: require("@/assets/msd.png")
              }
            ]
        })
    }
};
</script>

<style scoped>
.categories-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}
.institution-list{
    border: 0.5px solid #2950999c;
    height: 60px;
    margin: 4px;
    cursor: pointer;
    max-width: 49%;
    min-width: 49%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    padding: 10px;
    color: #09162e9c;
    box-shadow: -2px 2px 2px -3px rgba(0,0,0,0.88);
}

.institution-name{
    text-align: center;
    margin-left: 5px;
}

</style>
