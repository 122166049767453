<template>
  <v-container>
    Institution Goals
    <div @click="$router.push('objectives-targets')" class="institution-goal" v-for="(goal, i) in goals" :key="goal +'card' ">
        <div class="goal-name">
            <div>{{goal}}</div>
            <div class="goal-progress">Progress: {{ i+1 * 24 }} %</div>
        </div>
        <hr>
        <div class="goal-alignments">
            <div class="goal-links"><strong> SDG Alignments:</strong><br> Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.</div>
            <div class="goal-links"><strong> Africa Agenda 2063:</strong><br> Well educated citizens and skills revolution underpinned by science, technology and innovation.</div>
            <div class="goal-links"><strong> National Vision:</strong><br> Building a strong and resilient economy that can effectively withstand global competition</div>
            <div class="goal-links"><strong> FYDP:</strong><br> trengthen ICTs for Industrialization, Improve the quality of infrastructure and services</div>
            <div class="goal-links"><strong> Election Manifesto:</strong><br> Importance of communications infrastructure in creating and maintaining a sustainable economy, Improve quality of lives and enhance standard of living of Tanzanians</div>
        </div>
    </div>
  </v-container>
</template>

<script>
export default {
    data() {
        return({
            goals: [
            'Accessibility and affordability of Communications',
            'Services to unserved and underserved areas',
            'Cyber security incidents and misuse of Cyber Space',
            'Efficiency and reliability of communications Services',
            'Monitoring of Communications Services',
            'Innovation in Communications Sector',
            'Compliance on laws, regulations rules, guidelines and standards',
            'Public digital literacy and consumer awareness on communication services',
            'Systems and Processes to support operations',
            'Research and Studies on regulated services and emerging technologies;',
            'Stakeholders Engagement and Collaborations in regulating communication sector',
            ]
        })
    }
};

</script>

<style scoped>
.institution-goal{
    display: flex;
    flex-direction: column;
    border: 0.2px solid #5c7faf9c;
    padding: 10px;
    border-radius: 2px;
    margin-bottom: 6px;
    box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
    cursor: pointer;
}
.goal-name{
    margin-bottom: 15px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.goal-alignments{
    display: flex;
    font-size: 14px;
}
.goal-links{
    width: 19.5%;
    margin-top: 10px;
    margin-left: 2px;
    color: #09162e75;
}

.goal-progress{
    font-size: 13px;
    font-weight: 500;
    background-color: #1daa0db4;
    padding: 3px;
    border-radius: 15px;
    width: 100px;
    text-align: center;
    color: white;
}
</style>