<template>
  <sequential-entrance fromBottom>
    <v-container>
      <div class="categories-container">
        <div
          @click="$router.push('indicators-list')"
          class="institution-categories"
          v-for="category in categories"
          :key="category"
        >
          <v-icon size="30">mdi-lightbulb-on-outline</v-icon>
          <div class="category-name">{{ category }}</div>
        </div>
      </div>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        "Input Indicators (4)",
        "Output Indicators (3)",
        "Process Indicators (6)",
        "Results Indicators (11)"
      ],
    };
  },
};
</script>

<style scoped>
.categories-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.institution-categories {
  border: 0.5px solid #2950999c;
  height: 70px;
  margin: 4px;
  cursor: pointer;
  max-width: 19.1%;
  min-width: 19.1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
}

.category-name {
  text-align: center;
}
</style>
