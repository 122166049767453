<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <h4 class="dashboard-heads">Perfomance under FY 2023/2024</h4>
      <div class="categories-container">
        <div
          @click="$router.push('institution-list')"
          class="institution-categories success-goal"
        >
          <div class="percentage-perfom">84%</div>
          <div class="category-name">KPI's Achievement (21)</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories success-goal"
        >
          <div class="percentage-perfom">63%</div>
          <div class="category-name">SDG's Objectives (21)</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories warning-goal"
        >
          <div class="percentage-perfom">43%</div>
          <div class="category-name">AfA 2063 Objectives (21)</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories success-goal"
        >
          <div class="percentage-perfom">53%</div>
          <div class="category-name">CCM Manifesto Goals (21)</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories danger-goal"
        >
          <div class="percentage-perfom">23%</div>
          <div class="category-name">FYDP Objectives (21)</div>
        </div>
      </div>

      <h4 class="dashboard-heads">Conducted Meetings & Delibarations Reviews</h4>
      
      <div class="meetings-card">
        <div class="general-perfomance">
          <div class="text-name">General Perfomance</div>
          <div class="g-percentage"><h1>56%</h1></div>
        </div>
        <div class="general-body">
            <h1 class="body-text">138</h1>
          <div class="text-name">Scheduled Meetings</div>
        </div>
        <div class="general-body">
            <h1 class="body-text">97</h1>
          <div class="text-name">Conducted Meetings</div>
        </div>
        <div class="general-body">
            <h1 class="body-text">112</h1>
          <div class="text-name">Directives / Deliberations</div>
        </div>
        <div class="general-body-last">
            <h1 class="body-text">58</h1>
          <div class="text-name">Implemented Directives</div>
        </div>
      </div>

      <div class="panel-statistics">
        <div class="general-budget">
          <apexchart  width="700" type="area" :options="options" :series="series"></apexchart>
        </div>
        <div class="charts-panel">
          <ProjectGauge :heightData="350" :seriesData="[34]" />
          <h4 class="dashboard-heads">Budget Perfomance Reviews</h4>
        </div>
      </div>
    </v-container>
  </sequential-entrance>
</template>

<script>
import ProjectGauge from "../digitaltp_projects/digitaltp_components/project_details/ProjectGauge.vue";
export default {
  components: {
    ProjectGauge,
  },
  name: "DashBoard",
  data: () => ({
      options: {
        chart: {
          height: 100,
          stacked: false,
        
        },
        title: {
          text: 'Monthly Budjet Performance Overview (in 000,000,000)',
          align: 'left'
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug','Sept','Nov','Dec']
        },
        stroke: {
          curve: 'smooth',
        }
      },
      series: [{
        name: 'Planned Budget',
        data: [2.3,3.1,4.4,1.9,2.8,3.3,4.1,3.2,3.1,4.6,2.7]
        
      },{
        name: 'Disbused Budget',
        data: [2.4,2.5,3.2,2,2.6,3,2.7,1.1,3.01,4.3,2.66]
      }]
  }),
};
</script>

<style scoped>
.percentage-perfom{
  font-size: 30px;
  font-weight: bold;
}
.categories-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.institution-categories {
  border: 0.5px solid #5c7faf9c;
  height: 84px;
  margin: 2px;
  cursor: pointer;
  max-width: 19.1%;
  min-width: 19.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
}
.success-goal {
  background-color: rgba(0, 255, 136, 0.274);
}
.warning-goal {
  background-color: rgba(255, 153, 0, 0.486);
}
.danger-goal {
  background-color: rgba(255, 0, 0, 0.274);
}

.category-name {
  text-align: center;
}

.dashboard-heads {
  color: #09162e9c;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: bolder;
}

.meetings-card{
  background-color: white;
  border: 0.2px solid #09162e93;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
}

.general-perfomance{
  margin-right: 2px;
  padding-right:5px ;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  text-align: center;
}
.g-percentage{
  color: rgb(30, 138, 75);
}
.text-name{
  color: #09162eb4;
  font-weight: 500;
}

.general-body{
  margin-right: 2px;
  padding-right:5px ;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-body-last{
  margin-right: 2px;
  padding-right:5px ;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-text{
  font-size: 50px;
  color:#09162eb4;
  margin-left: 8px;
  margin-right: 5px;
}

.panel-statistics{
  display: flex;
}
.charts-panel{
  margin-top:20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.general-budget{
  margin-top:10px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
