var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_vm._v(" Targets Indicators List "),_c('v-card',{staticClass:"col-md-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":item.progress > 60
              ? 'green'
              : item.progress < 45
              ? 'orange'
              : 'blue',"buffer-value":100,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.progress),callback:function ($$v) {_vm.$set(item, "progress", $$v)},expression:"item.progress"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }