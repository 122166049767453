<template>
  <v-container>
    <h4 style="color: #111c2b9c">
      A. IMPLEMENTATION MATRIX FOR COMPETITIVE ECONOMY INTERVENTIONS
    </h4>
    <div>
      <div class="top-cards">
        <div @click="$router.push('indicators-list')" class="focus-area">
          <v-icon>mdi-cash-100</v-icon>
          <div class="percentage-perfom">19,890,639,800,000 TSh</div>
          <div class="category-name">Government</div>
        </div>
        <div @click="$router.push('indicators-list')" class="focus-area">
          <v-icon>mdi-cash-100</v-icon>
          <div class="percentage-perfom">2,000,000,000 TSh</div>
          <div class="category-name">DPs</div>
        </div>
        <div @click="$router.push('indicators-list')" class="focus-area">
          <v-icon>mdi-cash-100</v-icon>
          <div class="percentage-perfom">69,772,400,000,000 TSh</div>
          <div class="category-name">Private Sectors</div>
        </div>
        <div @click="$router.push('indicators-list')" class="focus-area">
          <v-icon>mdi-cash-100</v-icon>
          <div class="percentage-perfom">89,665,039,800,000 TSh</div>
          <div class="category-name">Total</div>
        </div>
      </div>

      <v-card elevation="0">
        <table>
          <thead>
            <tr>
              <th rowspan="4">S/No.</th>
              <th rowspan="4">Areas of Interventions</th>
              <th colspan="4" rowspan="4">Targets by 2025</th>
              <th colspan="4" rowspan="4">
                Programmes/ Projects
              </th>
              <th colspan="4">Budget</th>
              <th colspan="4" rowspan="4">Expected Outputs</th>
              <th colspan="2">Progress</th>
              <th colspan="4" rowspan="4">Actions</th>
            </tr>
            <tr>
              <th>Government</th>
              <th>DPs</th>
              <th>Private</th>
              <th>Total</th>
              <th>Financial</th>
              <th>Physical</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, Index) in reportData" :key="item.name">
              <td>{{ Index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td colspan="4">{{ item.duration }}</td>
              <td colspan="4">{{ item.startDate }}</td>
              <td>{{ item.Government }}</td>
              <td>{{ item.DPs }}</td>
              <td>{{ item.Private }}</td>
              <td>{{ item.Total }}</td>
              <td colspan="4">{{ item.expected }}</td>

              <td>80%</td>
              <td>20%</td>
              
              <td colspan="4">
                <v-menu :key="item.name" offset-y app>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      slot="activator"
                      icon
                      color="primary"
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </template>
                  <v-list dense>
                    <v-list-item link to="">
                      <v-list-item-title
                        ><v-icon small>mdi-summit</v-icon> Financial
                        Reports</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link to="implementations">
                      <v-list-item-title
                        ><v-icon small>mdi-numeric-positive-1</v-icon> Progress
                        Reports</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link to="">
                      <v-list-item-title
                        ><v-icon small>mdi-timeline-plus-outline</v-icon> View
                        Progress</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    reportData: [
      {
        name: `Strengthen the availability and reliability of electrical power by increasing generation capacity, transmission, and distribution networks`,
        duration: `Electricity production increased by 5,760 MW`,
        startDate: "Julius Nyerere Hydropower Project (2,115 MW)",
        Government: "5,817,690,000,000.00",
        DPs: "-",
        Private: "-",
        Total: "5,817,690,000,000.00",
        expected: "Julius Nyerere Hydropower Project completed by Jan, 2022",
      },
      {
        name: ``,
        duration: ``,
        startDate: "Kinyerezi I Extension-gas-fired plant (185MW) ",
        Government: "60,000,000,000.00",
        DPs: "-",
        Private: "-",
        Total: "60,000,000,000.00",
        expected: "Project completed by July, 2021",
      },
      {
        name: ``,
        duration: ``,
        startDate:
          "Rusumo Hydro Power Project (80MW), to be equally shared by Tanzania, Burundi and Rwanda",
        Government: "496,187,000,000.00",
        DPs: "-",
        Private: "-",
        Total: "496,187,000,000.00",
        expected: "Project completed by Feb, 2021",
      },
      {
        name: ``,
        duration: ``,
        startDate: "Ruhudji Hydro Power Project (358MW)",
        Government: "965,272,800,000.00",
        DPs: "-",
        Private: "-",
        Total: "965,272,800,000.00",
        expected: "Project completed by June, 2024",
      },
    ],
  }),
};
</script>

<style scoped>
.top-cards {
  display: flex;
  flex-direction: row;
}
.percentage-perfom {
  font-size: 19px;
  font-weight: bold;
  color: #616977de;
}
.focus-area {
  width: 25%;
  height: 100px;
  border: 1px solid #2950994d;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 15px;
  text-align: center;
  box-shadow: 2px 2px 5px -5px #29509975;
  padding: 10px;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #224e9ede;
  color: var(--dtp-font-white);
}

th {
  text-align: center;
  padding: 3px;
  border: 1px solid white;
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid #2950994d;
}
</style>
