<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <h4 class="dashboard-heads">Perfomance under FY 2023/2024</h4>
      <div class="categories-container">
        <div
          @click="$router.push('institution-list')"
          class="institution-categories success-goal"
        >
          <div class="percentage-perfom">67%</div>
          <div class="category-name">Quality Livelihood</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories success-goal"
        >
          <div class="percentage-perfom">63%</div>
          <div class="category-name">Peace, Stability & Unity</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories warning-goal"
        >
          <div class="percentage-perfom">43%</div>
          <div class="category-name">Good Governance</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories success-goal"
        >
          <div class="percentage-perfom">53%</div>
          <div class="category-name">Education</div>
        </div>
        <div
          @click="$router.push('institution-list')"
          class="institution-categories danger-goal"
        >
          <div class="percentage-perfom">23%</div>
          <div class="category-name">Competitive Economy </div>
        </div>
      </div>

      <h4 class="dashboard-heads">
        Conducted Meetings & Delibarations Reviews
      </h4>

      <div class="meetings-card">
        <div class="general-perfomance">
          <div class="text-name">General Perfomance</div>
          <div class="g-percentage"><h1>49.8%</h1></div>
        </div>
        <div class="general-body">
          <h1 class="body-text">14</h1>
          <div class="text-name">Evaluation Meetings</div>
        </div>
        <div class="general-body">
          <h1 class="body-text">97</h1>
          <div class="text-name">Conducted Meetings</div>
        </div>
        <div class="general-body">
          <h1 class="body-text">112</h1>
          <div class="text-name">Evaluation Directives / Deliberations</div>
        </div>
        <div class="general-body-last">
          <h1 class="body-text">58</h1>
          <div class="text-name">Implemented Directives</div>
        </div>
      </div>

      <div class="panel-statistics">
        <div class="general-budget">
          <apexchart
            width="700"
            type="area"
            :options="options"
            :series="series"
          ></apexchart>
        </div>
        <div class="charts-panel">
          <ProjectGauge
            :label="'Sectorial Perfomance'"
            :heightData="350"
            :seriesData="[34]"
          />
          <h4 class="dashboard-heads">General Perfomance Reviews</h4>
        </div>
      </div>

      <h4 class="dashboard-heads">Key Performance Indicators (KPIs) Reviews</h4>
      <div>
        <table>
          <thead>
            <tr>
              <th rowspan="2"><strong>S/No.</strong></th>
              <th rowspan="2"><strong>Indicator Name</strong></th>
              <th colspan="2" rowspan="2"><strong>Baseline</strong></th>
              <th colspan="2"><strong>Intermediate Targets</strong></th>
              <th colspan="2" rowspan="2"><strong>End Targets</strong></th>
              <th colspan="2" rowspan="2"><strong>Progress</strong></th>
            </tr>
            <tr>
              <th><strong>1</strong></th>
              <th><strong>2</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in reportData" :key="item.name">
              <td>1.0</td>
              <td>{{ item.name }}</td>
              <td colspan="2">{{ item.duration }}</td>
              <td>{{ item.duration }}</td>
              <td>{{ item.duration }}</td>
              <td colspan="2">{{ item.duration }}</td>
              <td colspan="2">
                <span>
                  <v-progress-linear
                    :color="
                      item.progress > 60
                        ? 'green'
                        : item.progress < 45
                        ? 'orange'
                        : 'blue'
                    "
                    v-model="item.progress"
                    :buffer-value="100"
                    height="20"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-container>
  </sequential-entrance>
</template>

<script>
import ProjectGauge from "../digitaltp_projects/digitaltp_components/project_details/ProjectGauge.vue";
export default {
  components: {
    ProjectGauge,
  },
  name: "DashBoard",
  data: () => ({
    options: {
      chart: {
        height: 100,
        stacked: false,
      },
      title: {
        text: "Sectorial Performance Review",
        align: "left",
      },
      xaxis: {
        categories: [
          "Water",
          "Health",
          "Agriculture",
          "Transport",
          "Trade",
          "Financial",
        ],
      },
      stroke: {
        curve: "smooth",
      },
    },
    series: [
      {
        name: "Planned Goals",
        data: [18, 23, 34, 7, 13, 23],
      },
      {
        name: "Achived Goals",
        data: [6, 15, 16, 8, 15, 20],
      },
    ],
    reportData: [
      {
        name: `Increase of Gross Domestic Product (GDP) Growth Rate`,
        duration: 52.0,
        startDate: "January 4, 2022",
        endDate: "December 4, 2023",
        progress: 34,
      },
      {
        name: `Reduce Income Inequality Index`,
        duration: 240.0,
        startDate: "January 4, 2022",
        endDate: "December 4, 2025",
        progress: 69,
      },
      {
        name: `Road Network Expansion`,
        duration: 25.0,
        startDate: "January 4, 2022",
        endDate: "December 4, 2023",
        progress: 49,
      },
      {
        name: `Percentage of Electricity Access users`,
        duration: 46.7,
        startDate: "January 4, 2022",
        endDate: "December 4, 2023",
        progress: 10,
      },
      {
        name: `Number of Net Enrollment Rate in Primary and Secondary Education`,
        duration: 0.0,
        startDate: "January 4, 2022",
        endDate: "December 4, 2025",
        progress: 69,
      },
    ],
  }),
};
</script>

<style scoped>
.percentage-perfom {
  font-size: 30px;
  font-weight: bold;
}
.categories-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.institution-categories {
  border: 0.5px solid #5c7faf9c;
  height: 84px;
  margin: 2px;
  cursor: pointer;
  max-width: 19.1%;
  min-width: 19.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
}
.success-goal {
  background-color: rgba(0, 255, 136, 0.274);
}
.warning-goal {
  background-color: rgba(255, 153, 0, 0.486);
}
.danger-goal {
  background-color: rgba(255, 0, 0, 0.274);
}

.category-name {
  text-align: center;
}

.dashboard-heads {
  color: #09162e9c;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: bolder;
}

.meetings-card {
  background-color: white;
  border: 0.2px solid #09162e93;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
}

.general-perfomance {
  margin-right: 2px;
  padding-right: 5px;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  text-align: center;
}
.g-percentage {
  color: rgb(30, 138, 75);
}
.text-name {
  color: #09162eb4;
  font-weight: 500;
}

.general-body {
  margin-right: 2px;
  padding-right: 5px;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-body-last {
  margin-right: 2px;
  padding-right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.body-text {
  font-size: 50px;
  color: #09162eb4;
  margin-left: 8px;
  margin-right: 5px;
}

.panel-statistics {
  display: flex;
}
.charts-panel {
  margin-top: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.general-budget {
  margin-top: 10px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding: 10px;
}

/* thead { */
/* background-color: var(--dtp-primary-color); */
/* border: 1px solid $wrrb-primary-color; */
/* color: var(--dtp-font-white); */
/* } */

th {
  text-align: center;
  padding: 5px;
  border-top: none;
  border: 0.2px solid var(--dtp-font-dark);
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid var(--dtp-font-dark);
}
</style>
