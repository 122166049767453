<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <h4 class="dashboard-heads">Perfomance under FY 2023/2024</h4>
      <div class="categories-container">
        <div
          @click="$router.push('indicators-list')"
          class="institution-categories success-card"
        >
          <div class="percentage-perfom">23</div>
          <div class="category-name">FYDP Objectives</div>
        </div>
        <div
          @click="$router.push('indicators-list')"
          class="institution-categories success-card"
        >
          <div class="percentage-perfom">84</div>
          <div class="category-name">Input Indicators</div>
        </div>
        <div
          @click="$router.push('indicators-list')"
          class="institution-categories success-card"
        >
          <div class="percentage-perfom">63</div>
          <div class="category-name">Output Indicators</div>
        </div>
        <div
          @click="$router.push('indicators-list')"
          class="institution-categories success-card"
        >
          <div class="percentage-perfom">43</div>
          <div class="category-name">Outcome Indicators</div>
        </div>
        <div
          @click="$router.push('indicators-list')"
          class="institution-categories success-card"
        >
          <div class="percentage-perfom">53</div>
          <div class="category-name">Impact Indicators</div>
        </div>
      </div>

      <h4 class="dashboard-heads">
        FINANCIAL PERFOMANCE IMPLEMENTATION OF FYDP III INTERVENTIONS UNDER FY
        2023/2024
      </h4>
      <div class="second-level">
        <div class="institution-graph">
          <apexchart
            height="500"
            width="75%"
            type="bar"
            :options="options"
            :series="series"
          ></apexchart>
        </div>
        <div class="institution-statistics">
          <h4 style="width: 100%; padding-left: 10px">
            Output over Intermediate Outcome
          </h4>
          <div
            @click="$router.push('indicators-list')"
            class="statistics-card grid-item warning-card"
          >
            <div class="percentage-perfom">4</div>
            <div class="category-name">Competitiveness</div>
          </div>
          <div
            @click="$router.push('indicators-list')"
            class="statistics-card grid-item warning-card"
          >
            <div class="percentage-perfom">4</div>
            <div class="category-name">Industrialization & Services</div>
          </div>
          <div
            @click="$router.push('indicators-list')"
            class="statistics-card grid-item warning-card"
          >
            <div class="percentage-perfom">3</div>
            <div class="category-name">Trade</div>
          </div>
          <div
            @click="$router.push('indicators-list')"
            class="statistics-card grid-item warning-card"
          >
            <div class="percentage-perfom">6</div>
            <div class="category-name">Skills Development</div>
          </div>
          <div
            @click="$router.push('indicators-list')"
            class="statistics-card grid-item warning-card"
          >
            <div class="percentage-perfom">3</div>
            <div class="category-name">Competitiveness</div>
          </div>
        </div>
      </div>

      <h4 class="dashboard-heads">
        TANZANIA’S POSITION IN THE EASE OF DOING BUSINESS REPORTS (2015-2025)
      </h4>
      <div class="second-level">
        <div
          class="institution-categories"
        >
          <div class="percentage-perfom">5.6% <v-icon color="success">mdi-arrow-up</v-icon></div>
          <div class="category-name">Average Growth</div>
        </div>
        <table>
          <thead>
            <tr>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: center;" class="">Year</td>
              <td style="text-align: center;" class="success-goal">2015</td>
              <td style="text-align: center;" class="warning-goal">2016</td>
              <td style="text-align: center;" class="success-goal">2017</td>
              <td style="text-align: center;" class="warning-goal">2018</td>
              <td style="text-align: center;" class="danger-goal">2019</td>
              <td style="text-align: center;" class="success-goal">2020</td>
            </tr>
            <tr>
              <td style="text-align: center;" >Position</td>
              <td style="text-align: center;" class="success-goal">131</td>
              <td style="text-align: center;" class="warning-goal">139</td>
              <td style="text-align: center;" class="success-goal">132</td>
              <td style="text-align: center;" class="warning-goal">137</td>
              <td style="text-align: center;" class="danger-goal">144</td>
              <td style="text-align: center;" class="success-goal">141</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  components: {},
  name: "DashBoard",
  data: () => ({
    options: {
      chart: {
        height: 100,
        stacked: false,
      },
      title: {
        text: "Budjet Performance Overview (in 000,000,000,000)",
        align: "left",
      },
      xaxis: {
        categories: ["Government", "DPs", "Private Sector"],
      },
      stroke: {
        curve: "smooth",
      },
    },
    series: [
      {
        name: "Planned Budget",
        data: [3432, 45647, 4214],
      },
      {
        name: "Disbused Budget",
        data: [3232, 23147, 32617],
      },
    ],
  }),
};
</script>

<style scoped>
.second-level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.percentage-perfom {
  font-size: 30px;
  font-weight: bold;
}
.categories-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.institution-statistics {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.statistics-card {
  border: 0.5px solid #5c7faf9c;
  height: 150px;
  margin: 5px;
  cursor: pointer;
  max-width: 45%;
  min-width: 44%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
}
.institution-graph {
  width: 70%;
  border: 0.5px solid #5c7faf9c;
  margin: 2px;
  border-radius: 3px;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.473);
}
.institution-categories {
  border: 0.5px solid #5c7faf9c;
  height: 95px;
  margin: 2px;
  cursor: pointer;
  max-width: 19.1%;
  min-width: 19.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  color: #09162e9c;
  box-shadow: 0px -1px 3px -2px rgba(0, 0, 0, 0.88);
}
.success-goal {
  background-color: rgba(0, 255, 136, 0.274);
}
.warning-goal {
  background-color: rgba(255, 153, 0, 0.486);
}
.danger-goal {
  background-color: rgba(255, 0, 0, 0.274);
}

.category-name {
  text-align: center;
}

.dashboard-heads {
  color: #09162e9c;
  padding-bottom: 10px;
  padding-top: 20px;
  font-weight: bolder;
}

.meetings-card {
  background-color: white;
  border: 0.2px solid #09162e93;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
}

.general-perfomance {
  margin-right: 2px;
  padding-right: 5px;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  text-align: center;
}
.g-percentage {
  color: rgb(30, 138, 75);
}
.text-name {
  color: #09162eb4;
  font-weight: 500;
}

.general-body {
  margin-right: 2px;
  padding-right: 5px;
  border-right: 4px solid rgba(44, 41, 110, 0.479);
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-body-last {
  margin-right: 2px;
  padding-right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.body-text {
  font-size: 50px;
  color: #09162eb4;
  margin-left: 8px;
  margin-right: 5px;
}

.panel-statistics {
  display: flex;
}
.charts-panel {
  margin-top: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.general-budget {
  margin-top: 10px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #224e9ede;
  color: var(--dtp-font-white);
}

th {
  text-align: center;
  padding: 3px;
  border: 1px solid white;
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid #2950994d;
}
</style>
